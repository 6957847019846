<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="450px">
                <v-card>
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 775px;">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                        <v-form id="form_rfid_user" ref="form" v-model="valid" lazy-validation>
                        <!-- začetni datum -->
                        <v-menu
                            ref="start_date_menu"
                            :close-on-content-click="false"
                            v-model="start_date_menu"
                            :nudge-right="40"
                            :return-value.sync="restriction_start_date"
                            
                            transition="scale-transition"
                            offset-y
                            
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-on="on"
                                    v-model="restriction_start_date_formatted"
                                    label="Veljavnost od"
                                    append-icon="mdi-calendar-today"
                                    readonly
                                    clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                            </v-date-picker>
                        </v-menu>

                        <!-- končni datum -->
                        <v-menu
                            ref="end_date_menu"
                            :close-on-content-click="false"
                            v-model="end_date_menu"
                            :nudge-right="40"
                            :return-value.sync="restriction_end_date"
                            
                            transition="scale-transition"
                            offset-y
                            
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-on="on"
                                    v-model="restriction_end_date_formatted"
                                    label="Veljavnost do"
                                    append-icon="mdi-calendar"
                                    readonly
                                    clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                            </v-date-picker>
                        </v-menu>

                        <v-text-field
                            v-model="reservation"
                            label="Rezervacija"
                            required
                            clearable
                            append-icon="mdi-pencil"
                            :rules="reservation_rules"
                        ></v-text-field>
                        <v-text-field
                            v-model="parcel_number"
                            label="Številka parcele"
                            required
                            clearable
                            append-icon="mdi-pencil"
                        ></v-text-field>
                        <v-text-field
                            v-model="phone_number_1"
                            label="Telefonska št. 1"
                            clearable
                            append-icon="mdi-cellphone-iphone"
                        ></v-text-field>
                        <v-text-field
                            v-model="registration_number_2"
                            label="Registrska št. 2"
                            clearable
                            append-icon="mdi-car"
                        ></v-text-field>
                        <v-select
                            :items="restrictionTypes"
                            label="Tip omejitve"
                            item-text="name"
                            item-value="id"
                            v-model="restriction_id"
                            persistent-hint
                        ></v-select>
                        <div style="min-height:10px;"></div>
                        <v-textarea
                        outlined
                        name="input-7-4"
                        label="Opombe"
                        v-model="note"
                        counter
                        required
                        ></v-textarea>

                        <v-switch
                            v-model="registration_numbers_enabled"
                            :label="`Dovoli dostop: ${userRegistrationNumbersEnabled}`"
                            color="secondary"
                            hide-details
                        ></v-switch>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { formatDate, randomPinNumber } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
import { getCurrentDate } from '@/helpers/utilities.js'

export default {
    name: 'add-user-dialog',
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: true,
        formTitle: "",
        dialog: false,
        item: null,
        loading:false,
        disableSubmit: false,
        cancelText: "Prekliči",

        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,
        user_type: '2',
        restriction_start_date: null,
        restriction_end_date: null,
        reservation: null,
        room_number: null,
        parcel_number: null,
        phone_number_1 : null,
        note: null,
        registration_number_1: null,
        registration_number_2: null,
        registration_numbers_enabled: true,
        restriction_id: null,
        restrictionTypes: [],

        phone_number_rules: [v => !!v || 'Polje za vnos telefonske številke je obvezno'],
        reservation_rules: [v => !!v || 'Polje za vnos rezervacije je obvezno'],

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[]

    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        restriction_start_date_formatted(val) {
            if(val == null) {
                this.restriction_start_date = null
            }
        },

        restriction_end_date_formatted(val) {
            if(val == null) {
                this.restriction_end_date = null
            }
        },

        registration_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_2 = this.registration_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_2 = this.registration_number_2.toUpperCase();
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        userRegistrationNumbersEnabled() {
            if(this.registration_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },
        
        submitForm() {
            var vm = this
            //window.console.log(vm.registration_number_1)
            if(this.$refs.form.validate()) {
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                vm.$store.dispatch('CREATE_RFID_USER', {
                    user_type: vm.user_type,
                    reservation: vm.reservation,
                    room_number: vm.room_number,
                    parcel_number: vm.parcel_number,
                    phone_number_1: vm.phone_number_1,
                    registration_number_1: vm.registration_number_1,
                    registration_number_2: vm.registration_number_2,
                    registration_numbers_enabled: vm.registration_numbers_enabled,
                    restriction_start_date: vm.restriction_start_date,
                    restriction_end_date: vm.restriction_end_date,
                    note: vm.note
                })
                .then(response => {
                    //window.console.log(response)
                    vm.backendMessages.push('Uporabnik je bil uspešno vnesen v sistem.');
                    vm.disableSubmit = true
                    vm.cancelText = "Zaključi"

                })
                .catch(error => {
                    console.log('CREATE RFID USER error')
                    console.log(error)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri zapisovanju uporabnika je prišlo do napake. Poskusite ponovno.')
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false
                    },600)
                })
            }
        },

        cancel() {
            this.clearForm()
            this.item = null
            this.dialog = false
            this.restriction_start_date = getCurrentDate()
            this.restriction_end_date = getCurrentDate()
            EventBus.$emit('refresh_dt_events', null)
            
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
        },

        setDefaultFormValues() {
            this.restriction_start_date = null
            this.restriction_end_date = null
            this.reservation = null
            this.room_number = null
            this.parcel_number = null
            this.phone_number_1 = null
            this.registration_number_2 = null
            this.restriction_id = null
            this.note = null
            this.registration_numbers_enabled = true
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        setFormTitle() {
            if(this.item != null && this.item != undefined) {
                if(this.item.device_id == 1) {
                   this.formTitle = 'Vhod: ' + this.item.registration_plate_number.toUpperCase() + " - " + this.item.event_timestamp
                   return
                }

                this.formTitle = 'Izhod: ' + this.item.registration_plate_number.toUpperCase() + " - " + this.item.event_timestamp
            }
        }
    },

    created() {
        var vm = this;
        EventBus.$on('ADD_USER_DIALOG', function(data) {
            vm.item = data
            vm.registration_number_1 = data.registration_plate_number
            vm.setFormTitle();
            vm.dialog = true;
        });
    },

    mounted() {
        this.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //console.log(response.data)
            this.restrictionTypes = response.data.restrictionTypes
        })
        .catch(error => {
            console.log("error")
            console.log(error)
        })

        this.restriction_start_date = getCurrentDate();
        this.restriction_end_date = getCurrentDate();
    },

    destroyed() {
        //EventBus.$off('ADD_USER_DIALOG')
    }
}

</script>

<style scoped>

</style>